import { sample } from 'effector';

import { notifyManyOnDoneFx } from '@core/utils/side-effects';

import * as content from '../content/internal';

import * as effects from './effects';
import * as store from './store';

/**
 * Trigger get notes after requested images and docs
 */
sample({
	clock: effects.requestImagesFx.done,
	fn: (sourceData) => ({ enquiryId: sourceData.id }),
	source: store.$enquiry,
	target: effects.getNotesFx,
});

/**
 * Fetch notes on create/delete
 */
sample({
	clock: [effects.createNoteFx.done, effects.deleteNoteFx.done],
	filter: (enquiryId) => !!enquiryId,
	fn: (enquiryId) => ({ enquiryId }),
	source: store.$enquiryId,
	target: effects.getNotesFx,
});

// Temporary measure to have some test vehicles as Trade
sample({
	clock: store.$enquiry,
	filter: ({ enquiry, tradeVehiclesPage }) =>
		tradeVehiclesPage.mockIsTrade && !enquiry?.isTrade && enquiry?.spec?.make === 'Honda',
	fn: ({ enquiry }) => ({ ...enquiry, isTrade: true }),
	source: {
		enquiry: store.$enquiry,
		tradeVehiclesPage: content.$tradeVehiclesPage,
	},
	target: store.$enquiry,
});

// Notify on vehicle changes
notifyManyOnDoneFx([
	[effects.confirmDetailsFx.done, 'Profile has been confirmed'],
	[effects.deleteNoteFx.done, 'Note was successfully deleted'],
	[effects.requestImagesFx.done, 'Images and additional docs requested'],
]);
