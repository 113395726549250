import type { Effect } from 'effector';
import { omit } from 'lodash-es';

import { TIME_24_HOURS } from '@core/constants';
import type { RequestConfig } from '@core/http';
import { request } from '@core/http';

import type {
	CalculateManualPrepCostRequest,
	CalculateManualPrepCostResponse,
	ManualPrepCostConfigResponseV2,
	ManualPrepCostResponse,
	SavePrepCostRequest,
} from './model';
import { domain } from './utils';

/**
 * Get all manual prep cost items available to an enquiry
 */
export const getManualPrepCostListFx: Effect<string | number, ManualPrepCostConfigResponseV2> = domain.effect({
	handler: (enquiryId) =>
		request({
			cacheTTL: TIME_24_HOURS,
			method: 'get',
			url: `/prep-costs/manualPrep/config/${enquiryId}`,
		} as RequestConfig),
});

/**
 * Get manual prep cost list for an enquiry
 */
export const getManualPrepCostFX: Effect<string | number, ManualPrepCostResponse> = domain.effect({
	handler: (enquiryId) =>
		request({
			method: 'get',
			url: `/prep-costs/manualPrep/${enquiryId}`,
		} as RequestConfig),
});

/**
 * Calculate manual prep cost for an enquiry
 */
export const calculateManualPrepCostFX: Effect<CalculateManualPrepCostRequest, CalculateManualPrepCostResponse> =
	domain.effect({
		handler: ({ enquiryId, slugs, ...data }) =>
			request({
				data: omit(data, 'all'),
				method: 'post',
				params: {
					slugs: slugs?.join(),
				},
				url: `/prep-costs/manualPrep/${enquiryId}/calculate`,
			} as RequestConfig),
	});

/**
 * Update manual prep cost
 */
export const updateManualPrepCostFX: Effect<SavePrepCostRequest, ManualPrepCostResponse> = domain.effect({
	handler: ({ data, enquiryId }) =>
		request({
			data: { data },
			method: 'post',
			url: `/prep-costs/manualPrep/${enquiryId}/upsert`,
		} as RequestConfig),
});
