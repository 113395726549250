import type { AxiosError } from 'axios';
import type { Effect } from 'effector';

import type { RequestConfig } from '@core/http';
import { request } from '@core/http';

import type { FinanceSettlementQuery } from './model';
import { domain } from './utils';

export const getFinanceSettlementData: Effect<FinanceSettlementQuery, void, AxiosError> = domain.effect({
	handler: (query: FinanceSettlementQuery) =>
		request({
			data: query,
			headers: {
				'x-mway-user': query.authToken,
			},
			method: 'POST',
			url: '/finance-settlement/blackhorse',
		} as RequestConfig),
});
